@import url('https://fonts.googleapis.com/css?family=Karla:400,700&display=swap');


@font-face {
    font-family: thisExistsFont;
    src: url(../../public/fonts/FluidBold-L37M5.ttf);
 }

.logo {
    font-family: thisExistsFont;
    color: whitesmoke;
    text-align: center;
    font-size: 12em;
    line-height: 1em;
    padding-bottom: 50px;
}

.logo-sub {
    font-family: thisExistsFont;
    color: whitesmoke;
    text-align: center;
    font-size: 10em;
    line-height: .4em;
    padding-bottom: 150px;
}

:root {
    --bgColor: #223344;
    --bgColor2: #090a0f;
    --accentColor: #FFF;
    --font: 'Karla', sans-serif;
    --delay: .3s;
}

.welcome-area  {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background: url('../assets/rain.jpeg');
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    animation: 1s ease-out var(--delay) 1 transitionAnimation; /* duration/timing-function/delay/iterations/name */
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-size: cover;
}

#music {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background: url('../assets/Tucson.jpg');
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
}

#videos {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background: url('../assets/CostaRica.jpeg');
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    overflow: hidden;
}

#about {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background: url('../assets/Sedona.jpeg');
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    overflow: hidden;
}

#more {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background: url('../assets/sal3.jpeg');
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
}

#userName {
    color: var(--accentColor);
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25;
    display: block;
    font-family: var(--font);
    width: 100%;
    text-align: center;
    text-decoration: none;
}

#links {
    max-width: 675px;
    width: auto;
    display: block;
    margin-top: 30px;
    margin: 40px auto;
}

.link {
    position: relative;
    background-color: transparent;
    color: var(--accentColor);
    cursor: pointer;
    border: solid var(--accentColor) 2px;
    border-radius: 10px;
    font-size: 1rem;
    text-align: center;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px; /* 17px */
    text-decoration: none;
    /* transition: all .25s cubic-bezier(.08, .59, .29, .99); */
    -webkit-tap-highlight-color: transparent;
}

#songs {
    max-width: 300px;
    width: auto;
    display: block;
    margin-top: 30px;
    margin: 40px auto;
}

.songText {
    position: relative;
    background-color: transparent;
    color: var(--accentColor);
    cursor: pointer;
    font-size: 2rem;
    text-align: center;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px; /* 17px */
    text-decoration: none;
    /* transition: all .25s cubic-bezier(.08, .59, .29, .99); */
    -webkit-tap-highlight-color: transparent;
    transform: scale(1);
    transition: transform 0.3s ease-out;
}

.aboutText {
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 50%;
    width: 70%;
    font-size: 1em;
    background-color: rgba(0, 0, 0, 0.85);
    color:#FFF;
    text-align: left;
    padding: 2em;
    vertical-align: middle; 
    display: flex;
    flex-direction: column;
}

.video-container {
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 55%;
    width: 70%;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.spotify-container {
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 55%;
    width: 70%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.video {
    width:100%;
    height:100%;
    display: flex;
}

@media (hover: hover) {
    .link:hover {
        background-color: var(--accentColor);
        color: var(--bgColor);
    }

    .songText:hover {
        transform: scale(1.5);
        color: rgba(168, 168, 168, 0.85);
        transition: transform .6s;
    }
}

.link:active {
    background-color: var(--accentColor);
    color: var(--bgColor);
}

@media (max-width: 768px) {
    body {
        height:100%;
    }
    .logo {
        font-size: 7em;
    }
    .logo-sub {
        font-size: 5em;
        padding-top: 20px;
        padding-bottom: 70px;
    }
    #links {
        padding-top: 20px;
        margin:0px !important;
    }
    .link {
        padding:25px;
    }
    .aboutText {
        font-size: .8em;
    }
}

/*-------------------------animations-----------------------*/
@keyframes transitionAnimation {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}


/*-------------------------popup------------------------*/
/* credits: https://www.youtube.com/watch?v=lAS2glU0xlc */
.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: .5s ease-in-out;
}

.popup {
    position: relative;
    top: -43%;
    /* right: -100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    /* max-height: 500px; */
    width: auto;
    height: auto;
    margin: 56px;
    background-color: var(--bgColor);
    /* transform: rotate(32deg); */
    transform: scale(0);
    transition: .5s ease-in-out;
}

.popup-quote {
    font-family : Baskerville, Georgia, serif;
    font-style : italic;
    position: flex;
    color: var(--accentColor);
    padding: 20px;
    text-align: center;
    font-size: 1rem;
}

.popup-photo {
    display: flex;
    width: 100%;
    height: 100%;
}

.popup-photo img {
    width: 100%;
    height: 100%;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.overlay:target .popup {
    transform: scale(1);
    top: 0;
    /* right: 0; */
    /* transform: rotate(0); */
}

.popup-close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 3rem;
    height: 3rem;
    font-size: 1.7rem;
    font-weight: 400;
    border-radius: 100%;
    background-color: var(--bgColor);
    z-index: 4;
    color: var(--accentColor);
    line-height: 2.7rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
    .popup-close:hover {
        background-color: var(--accentColor);
        color: var(--bgColor);
    }
}

.popup-close:active {
    background-color: var(--accentColor);
    color: var(--bgColor);
}