.nav {
  height: 70px;
  display: flex;
  justify-content: center;
  background-color: rgba(126, 126, 126, .5);
}

.nav-link {
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
}

.nav-menu {
  display: flex;
  align-items: center;
  margin-right: -24px;
  white-space: nowrap; 
}

@media screen and (max-width: 768px) {
    .nav-menu {
        display: flex;
        height: 100%;
        width: 100%;
    }

    .nav {
        display: flex;
        justify-content:space-between;
      }

    .link-text {
        padding: 0px !important; 
        border: solid var(--accentColor) 1px;

    }

}

.link-text {
    position: relative;
    text-align: center;
    cursor: pointer;
    align-items:center;
    line-height: 70px;
    width: 100%;
    height: 100%;
    padding: 10px; /* 17px */
    color: #FFFFFF;
}

.link-text:hover {
    transition: all 0.2s ease-in-out;
    color: #101010;
}